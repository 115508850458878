import React from "react";
import "./HeroSection.scss";
import {BrowserView} from 'react-device-detect';

const HeroSection = () => {
  return (
    <BrowserView>
    <div className="networks-hero-container">
      <div className="hero-img"></div>
      <div className="hero-text-con d-flex flex-row">
        <div className="waw-headline">
          <p className="p1">Our</p>
          <p className="p2">Networks</p>
        </div>
      </div>
    </div>
    </BrowserView>
  );
};

export default HeroSection;
